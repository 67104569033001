<script setup lang="ts">
const prepareImgUrl = useImage();

const createImgUrl = (imageName: string) => {
	const imgUrl = `/nuxt-img/deposit-streak/mini-ferris/${imageName}`;
	return `url(${prepareImgUrl(imgUrl, { loading: "lazy", format: "avif" })})`;
};

const ground = createImgUrl("ground.png");
const pinkGondola = createImgUrl("gondola-pink.png");
const blueGondola = createImgUrl("gondola-blue.png");
const wheel = createImgUrl("wheel.png");
</script>

<template>
	<div class="mini-wheel-wrapper">
		<div class="mini-wheel">
			<div
				v-for="cabinCount in 8"
				:key="`mini-ferris-${cabinCount}`"
				:class="[
					'mini-cabin',
					{
						pink: cabinCount % 2 !== 1,
						blue: cabinCount % 2 === 1
					}
				]"
			/>
		</div>
	</div>
</template>

<style lang="scss" scoped>
.mini-wheel-wrapper {
	width: 96px;
	height: 110px;
	display: flex;
	justify-content: center;
	position: relative;

	&:before {
		content: "";
		position: absolute;
		width: 65px;
		height: 50px;
		top: 55px;
		left: 50%;
		transform: translateX(-50%);
		background: v-bind(ground) no-repeat center center / contain;
	}

	&::after {
		content: "";
		position: absolute;
		width: 50px;
		height: 50px;
		background: var(--chanduasi);
		box-shadow: 0 0 20px 10px var(--chanduasi);
		transform: translate(-50%, -50%);
		z-index: -1;
		border-radius: 50%;
		top: 43%;
		left: 50%;
	}

	.mini-wheel {
		position: absolute;
		width: 90px;
		height: 90px;
		background: v-bind(wheel) no-repeat center center / contain;
		animation: wheel 10s linear infinite;
	}

	.mini-cabin {
		position: absolute;
		width: 20px;
		height: 15px;
		transform-origin: 50% 0%;
		animation: cabins 10s linear infinite;

		&.blue {
			background: v-bind(blueGondola) no-repeat center center / cover;
		}

		&.pink {
			background: v-bind(pinkGondola) no-repeat center center / cover;
		}
	}

	.mini-cabin:nth-of-type(1) {
		right: 39.5%;
		top: 7px;
	}
	.mini-cabin:nth-of-type(2) {
		top: 19%;
		right: 10px;
	}
	.mini-cabin:nth-of-type(3) {
		right: -1px;
		top: 43%;
	}
	.mini-cabin:nth-of-type(4) {
		top: 74%;
		right: 8px;
	}
	.mini-cabin:nth-of-type(5) {
		right: 39.5%;
		bottom: -3px;
	}
	.mini-cabin:nth-of-type(6) {
		top: 74%;
		left: 8px;
	}

	.mini-cabin:nth-of-type(7) {
		left: -1px;
		top: 43%;
	}

	.mini-cabin:nth-of-type(8) {
		top: 19%;
		left: 11px;
	}
}

@keyframes wheel {
	0% {
		transform: rotate(0deg);
	}
	100% {
		transform: rotate(360deg);
	}
}

@keyframes cabins {
	0% {
		transform: rotate(0deg);
	}
	100% {
		transform: rotate(-360deg);
	}
}
</style>
